import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import {
  setIsConfirmDeleteModalOpen,
  treeHistoryRemove,
  removePlan,
} from "../../reducers/treeMapSlice";

const DeleteConfirmationDialog = () => {
  const dispatch = useDispatch();
  const isDeleteTreeconfirmModalOpen = useSelector(
    (state) => state.treeMap.isDeleteTreeconfirmModalOpen
  );
  const selectedTreeId = useSelector((state) => state.treeMap.selectedTreeId);
  const selectedTreeName = useSelector(
    (state) => state.treeMap.selectedDeleteTreeName
  );
  const planData = useSelector((state) => state.treeMap.planData);
  let deleteConfirmationModalType = useSelector(
    (state) => state.treeMap.deleteConfirmationModalType
  );
  const selectedTreeNode = useSelector(
    (state) => state.treeMap.selectedTreeNode
  );

  const handleDeleteClick = () => {
    (async () => {
      if (deleteConfirmationModalType === "plan") {
        if (planData) {
          dispatch(removePlan(selectedTreeId, selectedTreeNode.data.name));
        }
      } else {
        dispatch(treeHistoryRemove());
      }
    })();
  };

  const toggleDialog = () => {
    dispatch(
      setIsConfirmDeleteModalOpen({
        type: deleteConfirmationModalType,
        id: "",
        name: selectedTreeName,
      })
    );
  };

  return (
    <Dialog
      open={isDeleteTreeconfirmModalOpen}
      onClose={toggleDialog}
      sx={{
        textAlign: "center",
        backdropFilter: "blur(4px)",
        "& .MuiPaper-root": {
          borderRadius: "20px",
          padding: "0px 30px",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent",
        },
      }}
    >
      <DialogTitle
        sx={{ fontFamily: "ClashDisplay-medium", fontSize: "1.5rem" }}
      >
        {deleteConfirmationModalType === "plan"
          ? "Delete Plan"
          : "Delete Event"}
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            fontFamily: "ClashDisplay-medium",
          }}
        >
          {deleteConfirmationModalType === "plan"
            ? "Are you sure you want to delete the plan?"
            : `Are you sure you want to delete "${selectedTreeName}" event?`}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", paddingBottom: "20px" }}>
        <Button
          onClick={() => toggleDialog()}
          variant="contained"
          sx={{
            width: "80px",
            background: "white",
            color: "#1D77B7",
            borderRadius: 2,
            border: "1px solid #1D77B7",
            textTransform: "none",
            padding: "5px",
            "&:hover": {
              background: "white",
              boxShadow: "none",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => handleDeleteClick()}
          sx={{
            width: "80px",
            color: "white",
            textTransform: "none",
            backgroundColor: "rgb(244, 67, 54)",
            borderRadius: 2,
            "&:hover": {
              backgroundColor: "rgb(244, 67, 54)",
              boxShadow: "none",
            },
            ml: 3,
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
