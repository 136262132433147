import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "./ManageEvent.css";
import { themeJson } from "./theme";
import { useDispatch, useSelector } from "react-redux";
import {
  createEvent,
  editEvent,
  setSelectedUpdateTreeId,
  eventTitleUpdate,
} from "../../reducers/treeMapSlice";
import { useEffect } from "react";

const ManageEvent = ({
  first_event,
  setCreatedEvent,
  showManageEvent,
  setShowManageEvent,
}) => {
  const dispatch = useDispatch();
  const selectedUpdateTreeId = useSelector(
    (state) => state.treeMap.selectedUpdateTreeId
  );
  const eventsHistory = useSelector((state) => state.treeMap.history);
  const json = {
    completeText: "Generate",
    pages:
      showManageEvent === "situation"
        ? [
            {
              name: "User information",
              elements: [
                {
                  type: "panel",
                  name: "user-information",
                  elements: [
                    {
                      type: "dropdown",
                      name: "main_branches",
                      width: "32%",
                      minWidth: "192px",
                      titleLocation: "hidden",
                      descriptionLocation: "underInput",
                      choices: [3, 4, 5, 6],
                      defaultValue: 6,
                      allowClear: false,
                      description: "Main Branches",
                    },
                    {
                      type: "dropdown",
                      name: "sub_branches",
                      startWithNewLine: false,
                      width: "32%",
                      minWidth: "192px",
                      titleLocation: "hidden",
                      descriptionLocation: "underInput",
                      choices: [1, 2, 3, 4],
                      defaultValue: 4,
                      enableIf: "false",
                      allowClear: false,
                      description: "Sub Branches",
                    },
                    {
                      type: "dropdown",
                      name: "specific_actions",
                      startWithNewLine: false,
                      width: "32%",
                      minWidth: "192px",
                      titleLocation: "hidden",
                      descriptionLocation: "underInput",
                      choices: [1, 2, 3],
                      defaultValue: 3,
                      enableIf: "false",
                      allowClear: false,
                      description: "Actions",
                    },
                    {
                      type: "text",
                      name: "event",
                      title: "Event",
                      titleLocation: "hidden",
                      placeholder: "Event",
                      isRequired: true,
                    },
                    // {
                    //   titleLocation: "hidden",
                    //   type: "checkbox",
                    //   name: "model_type",
                    //   title: "Use Anthropic?",
                    //   choices: ["Anthropic (Claude 3.5 Sonnet)"],
                    // },
                    {
                      type: "comment",
                      name: "context",
                      title: "Context",
                      titleLocation: "hidden",
                      placeholder: "Context",
                      isRequired: true,
                    },
                  ],
                },
              ],
              title: "Generate the Inverted List",
            },
          ]
        : [
            {
              name: "User information",
              elements: [
                {
                  type: "panel",
                  name: "user-information",
                  elements: [
                    {
                      type: "dropdown",
                      name: "main_branches",
                      width: "32%",
                      minWidth: "192px",
                      titleLocation: "hidden",
                      descriptionLocation: "underInput",
                      choices: [3, 4, 5, 6],
                      allowClear: false,
                      defaultValue: 6,
                      description: "Main Branches",
                    },
                    {
                      type: "dropdown",
                      name: "sub_branches",
                      startWithNewLine: false,
                      width: "32%",
                      minWidth: "192px",
                      titleLocation: "hidden",
                      descriptionLocation: "underInput",
                      choices: [1, 2, 3, 4],
                      enableIf: "false",
                      defaultValue: 4,
                      allowClear: false,
                      description: "Sub Branches",
                    },
                    {
                      type: "dropdown",
                      name: "specific_actions",
                      startWithNewLine: false,
                      width: "32%",
                      minWidth: "192px",
                      titleLocation: "hidden",
                      descriptionLocation: "underInput",
                      choices: [1, 2, 3],
                      enableIf: "false",
                      defaultValue: 3,
                      allowClear: false,
                      description: "Actions",
                    },
                    {
                      type: "text",
                      name: "event",
                      title: "Name",
                      titleLocation: "hidden",
                      placeholder: "Name",
                      isRequired: true,
                    },
                    {
                      type: "text",
                      name: "job_title",
                      //   startWithNewLine: false,
                      title: "Title",
                      titleLocation: "hidden",
                      placeholder: "Title",
                      isRequired: true,
                    },
                    {
                      type: "text",
                      name: "company_name",
                      title: "Company Name",
                      titleLocation: "hidden",
                      placeholder: "Company Name",
                      isRequired: true,
                    },
                    // {
                    //   titleLocation: "hidden",
                    //   type: "checkbox",
                    //   name: "model_type",
                    //   title: "Use Anthropic?",
                    //   choices: ["Use Anthropic (Claude 3.5 Sonnet)"],
                    // },
                    {
                      type: "comment",
                      name: "context",
                      title: "Context",
                      titleLocation: "hidden",
                      placeholder: "Context",
                      isRequired: true,
                    },
                  ],
                },
              ],
              title: "Generate the Inverted List",
            },
          ],
  };
  const survey = new Model(json);

  useEffect(() => {
    if (selectedUpdateTreeId) {
      const updateEvent = eventsHistory.find(
        (el) => el._id === selectedUpdateTreeId
      );
      survey.data = {
        event:
          updateEvent?.requestType == 1
            ? updateEvent.employee_name
            : updateEvent.event_name,
        job_title: updateEvent?.job_title,
        company_name: updateEvent?.company_name,
        context: updateEvent?.context,
        main_branches: updateEvent?.branches?.main_branches,
        sub_branches: updateEvent?.branches?.sub_branches,
        specific_actions: updateEvent?.branches?.specific_actions,
        // model_type:
        //   updateEvent?.modelType === 2
        //     ? "Use Anthropic (Claude 3.5 Sonnet)"
        //     : undefined,
      };
    }
  }, [selectedUpdateTreeId, eventsHistory]);

  survey.addNavigationItem({
    title: "Cancel",
    action: () => {
      survey.currentPage.questions.forEach((question) => {
        question.value = undefined;
      });
      first_event && setCreatedEvent(true);
      setShowManageEvent(false);
      selectedUpdateTreeId && dispatch(setSelectedUpdateTreeId(""));
    },
    // css: "nav-button",
    // innerCss: "sd-btn nav-input",
  });

  survey.onComplete.add((sender) => {
    const {
      event,
      job_title,
      company_name,
      /* model_type, */
      context,
      main_branches,
      sub_branches,
      specific_actions,
    } = sender.data;
    // let modelType = model_type ? 2 : 1;
    let modelType = 2;
    first_event && setCreatedEvent(true);
    setShowManageEvent(false);
    const requestType = showManageEvent === "situation" ? 2 : 1;
    const updateEvent =
      selectedUpdateTreeId &&
      eventsHistory.find((el) => el._id === selectedUpdateTreeId);

    selectedUpdateTreeId
      ? updateEvent.requestType === 1 &&
        updateEvent.job_title === job_title &&
        // updateEvent.modelType === model_type &&
        updateEvent.company_name === company_name &&
        updateEvent.context === context &&
        updateEvent.branches.main_branches === main_branches &&
        updateEvent.branches.sub_branches === sub_branches &&
        updateEvent.branches.specific_actions === specific_actions
        ? dispatch(
            eventTitleUpdate(selectedUpdateTreeId, updateEvent.title, event)
          )
        : dispatch(
            editEvent(
              event,
              job_title,
              company_name,
              context,
              requestType,
              modelType,
              selectedUpdateTreeId,
              main_branches,
              sub_branches,
              specific_actions
            )
          )
      : setTimeout(
          () => {
            dispatch(
              createEvent(
                event,
                job_title,
                company_name,
                context,
                requestType,
                modelType,
                first_event,
                main_branches,
                sub_branches,
                specific_actions
              )
            );
          },
          first_event ? 500 : 10
        );
    selectedUpdateTreeId && dispatch(setSelectedUpdateTreeId(""));
  });

  survey.applyTheme(themeJson);

  return <Survey model={survey} />;
};

export default ManageEvent;
