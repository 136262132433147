import { useContext, createContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';

const AuthContext = createContext();
const apiUrl = `${process.env.REACT_APP_API_BASE_URL_DEV}/v1`;

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("accessToken") || "");
  const navigate = useNavigate();

  const signUp = async ({ email, password }) => {
    try {
      const response = await axios.post(`${apiUrl}/auth/signup`, { email, password }, {
        headers: {
          "x-application": "judo"
        }
      })
      if (response?.status === 200) {
        setUser(response.data.user);
        setToken(response.data.token);
        localStorage.setItem("accessToken", response.data.token);
        navigate("/subscribe")
        return response;
      }
      throw new Error(response.message);
    } catch (err) {
      console.error(err);
      return err;
    }
  };

  const signIn = async ({ email, password }) => {
    try {
      const response = await axios.post(`${apiUrl}/auth/signin`, { email, password }, {
        headers: {
          "x-application": "judo"
        }
      })
      if (response.data) {
        setUser(response.data.user);
        setToken(response.data.token);
        localStorage.setItem("accessToken", response.data.token);
        if (response.data.user?.judo_Application?.subscriptions.length && response.data.user.judo_Application.subscriptions[0].active) {
          navigate("/")
        }
        else navigate("/subscribe")
        return;
      }
      throw new Error(response.message);
    } catch (err) {
      console.error(err);
      return err;
    }
  };

  const signInWithGoogle = async (accessToken) => {
    try {
      const res = await axios.post(`${apiUrl}/auth/google-judo`, { idToken: accessToken })
      setUser(res.data.user);
      setToken(res.data.token);
      localStorage.setItem("accessToken", res.data.token)
      if (res.data.user?.judo_Application?.subscriptions.length && res.data.user.judo_Application.subscriptions[0].active) {
        navigate("/")
      }
      else navigate("/subscribe")
    } catch (error) {
      console.error("Google Sign-In Error:", error.message);
    }
  };

  return (
    <GoogleOAuthProvider clientId="842490433385-qv8flf7pikmt7mm4ndrj2rn1emohqu2t.apps.googleusercontent.com">
      <AuthContext.Provider value={{ token, user, signUp, signIn, signInWithGoogle }}>
        {children}
      </AuthContext.Provider>
    </GoogleOAuthProvider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};