import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddchartIcon from "@mui/icons-material/Addchart";
import DecisionTreeVisualization from "../DecisionTreeVisualization/DecisionTreeVisualization";
import Loader from "../Loader/Loader";
import IndentedList from "../IndentedList/IndentedList";

export default function Layout({ setShowManageEvent }) {
  const treeData = useSelector((state) => state.treeMap.eventData);
  const treeHistoryLoading = useSelector(
    (state) => state.treeMap.isTreeHistoryLoading
  );
  const treeId = useSelector((state) => state.treeMap.selectedTreeId);
  const eventHistory = useSelector((state) => state.treeMap.history);
  const loading = useSelector((state) => state.treeMap.loading);
  const visualizationType = useSelector(
    (state) => state.treeMap.visualizationType
  );
  const eventGenerating = useSelector((state) => state.treeMap.eventGenerating);
  const eventRatings = useSelector((state) => state.treeMap.eventRatings);
  const selectedTreePlannedNodeNames = useSelector(
    (state) => state.treeMap.selectedTreePlannedNodeNames
  );
  const selectedTreeNode = useSelector(
    (state) => state.treeMap.selectedTreeNode
  );
  const userData = useSelector((state) => state.treeMap.userData);
  const shareLinks = useSelector((state) => state.treeMap.shareLinks);
  const dispatch = useDispatch();

  return (
    <Box style={{ height: "100%", position: "relative" }}>
      {(treeHistoryLoading && !treeId) || loading ? (
        <Loader text={"Loading..."} />
      ) : treeData ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          {visualizationType === 2 ? (
            <DecisionTreeVisualization
              treeMap={
                treeData?.Pitfalls
                  ? {
                      name: eventHistory?.find((el) => el._id === treeId)
                        ?.job_title,
                      children: [...treeData.Pitfalls],
                    }
                  : treeData
              }
            />
          ) : (
            <IndentedList
              userData={userData}
              eventId={treeId}
              shareLinks={shareLinks}
              selectedTreeNode={selectedTreeNode}
              selectedTreePlannedNodeNames={selectedTreePlannedNodeNames}
              eventRatings={eventRatings}
              eventGenerating={eventGenerating}
              data={treeData?.Pitfalls ?? treeData?.children}
              dispatch={dispatch}
            />
          )}
        </Box>
      ) : (
        <Box className="create-chart-button-container">
          <Tooltip title="Generate new Event">
            <IconButton
              onClick={() => setShowManageEvent("role")}
              color="primary"
            >
              <AddchartIcon
                className="add-chart-icon"
                fontSize="large"
                color="primary"
              />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}
