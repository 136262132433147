import React, { useEffect, useState } from "react";
import "./IndentedList.css";
import { ChildlessRow } from "./ChildlessRow";
import { CollapseIcon } from "./CollapseIcon";
import { ReactComponent as BinIcon } from "../../assets/images/icons/deleteIcon.svg";
import { deleteBranch } from "../../reducers/treeMapSlice";

export const TableRow = ({
  isAssesmentLink,
  rowData,
  firstChild,
  collapseAll,
  setCollapseAll,
  childCollapsed,
  userData,
  selectedTreeNode,
  selectedTreePlannedNodeNames,
  eventRatings,
  originalEventRatings,
  handleEventRatings,
  dispatch,
}) => {
  const [childlessCollapsed, setChildlessCollapsed] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [averageRate, setAverageRate] = useState(undefined);
  const [rowHeight, setRowHeight] = useState();

  const toggleCollapse = () => {
    firstChild
      ? setCollapsed(!collapsed)
      : setChildlessCollapsed(!childlessCollapsed);
  };

  useEffect(() => {
    setRowHeight(
      document.getElementById(rowData.name)?.getBoundingClientRect().height - 18
    );
  }, []);

  useEffect(() => {
    if (collapseAll !== "finished") {
      setCollapsed(collapseAll === "collapsed" ? true : false);
      setChildlessCollapsed(
        collapseAll === "collapsed" || collapseAll === "expanded" ? true : false
      );
      setCollapseAll("finished");
    }
  }, [collapseAll]);

  useEffect(() => {
    setAverageRate(calculateAverageRate());
  }, [eventRatings]);

  const calculateAverageRate = () => {
    const rates = [];
    let rateSum = 0;
    rowData?.children?.forEach((el) =>
      el?.children
        ? el.children.forEach(
            (el) =>
              eventRatings.find(
                (elm) => elm.node_name == el.name && elm.rate > 0
              ) &&
              rates.push(eventRatings.find((elm) => elm.node_name == el.name))
          )
        : eventRatings.find(
            (elm) => elm.node_name == el.name && elm.rate > 0
          ) && rates.push(eventRatings.find((elm) => elm.node_name == el.name))
    );
    rates.forEach((el) => (rateSum += el.rate));
    return ((rateSum / rates.length / 5) * 100).toFixed();
  };

  return (
    <>
      <div
        className={`row ${
          firstChild ? "" : childCollapsed ? "collapsed" : "expanded"
        }`}
        style={{
          height: firstChild ? "auto" : childCollapsed ? 0 : rowHeight,
          paddingLeft: firstChild ? "10px" : "20px",
          backgroundColor: firstChild ? "#1976d2" : "rgb(127 148 169)",
          backgroundImage:
            firstChild &&
            `linear-gradient(90deg, #ff3d13 ${
              100 - averageRate - 20
            }%, #1976d2 ${
              100 -
              averageRate +
              (averageRate == NaN || averageRate == 100 ? 0 : 20)
            }%, #1976d2 100%)`,
          fontSize: firstChild ? "18px" : "16px",
          opacity: 1,
        }}
        id={rowData.name}
        key={rowData.name}
        onClick={toggleCollapse}
      >
        <CollapseIcon
          collapsed={firstChild ? collapsed : childlessCollapsed}
          firstChild={firstChild}
        />
        <p style={{ maxWidth: "calc(100% - 80px)", margin: 0 }}>
          {rowData.name}
        </p>
        <BinIcon
          className="deleteIconTableRow"
          onClick={(e) => (
            e.stopPropagation(), dispatch(deleteBranch(rowData.name))
          )}
        />
        {averageRate > 0 && (
          <div
            style={{
              fontSize: 14,
              position: "absolute",
              right: 15,
              alignSelf: "center",
              opacity: childCollapsed ? 0 : 1,
              transition: "all 200ms",
            }}
          >
            <span>{averageRate}%</span>
          </div>
        )}
      </div>
      {rowData?.children?.map((child, i) =>
        child.children ? (
          <TableRow
            isAssesmentLink={isAssesmentLink}
            rowData={child}
            key={child.name}
            collapseAll={collapseAll}
            childCollapsed={collapsed}
            setCollapseAll={setCollapseAll}
            userData={userData}
            selectedTreeNode={selectedTreeNode}
            selectedTreePlannedNodeNames={selectedTreePlannedNodeNames}
            eventRatings={eventRatings}
            originalEventRatings={originalEventRatings}
            handleEventRatings={handleEventRatings}
            dispatch={dispatch}
          />
        ) : (
          <ChildlessRow
            isAssesmentLink={isAssesmentLink}
            rowData={child}
            rowRating={eventRatings.find((el) => el.node_name == child.name)}
            originalEventRating={originalEventRatings?.find(
              (el) => el.node_name == child.name
            )}
            key={child.name}
            rowCollapsed={childlessCollapsed || childCollapsed}
            userData={userData}
            selectedTreeNode={selectedTreeNode}
            selectedTreePlannedNodeNames={selectedTreePlannedNodeNames}
            handleEventRatings={handleEventRatings}
            dispatch={dispatch}
          />
        )
      )}
    </>
  );
};
