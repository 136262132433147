import "./App.css";
import { useAuth } from "./services/auth";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Typography } from "@mui/material";
import Dashboard from "./pages/Dashboard";
import Loader from "./components/Loader/Loader";
import ErrorBoundary from "./ErrorBoundary";
import { getUserData } from "./services/api-service";
import { setUserData } from "./reducers/treeMapSlice";

function App() {
  const { user, token } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataState, setDataState] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  const userData = useSelector((state) => state.treeMap.userData);

  const GetUserData = async () => {
    if (token) {
      setDataLoading(true);
      await getUserData(token).then((res) => {
        if (res?.user?.judo_Application?.subscriptions.length && res.user.judo_Application.subscriptions[0].active) {
          dispatch(setUserData(res.user));
          localStorage.setItem("accessToken", res.token)
        } else navigate("/subscribe");
      }).catch(() => setDataState("error")).finally(() => setDataLoading(false))
    }
    else navigate("/signin");
  }

  useEffect(() => {
    if (!dataLoading) {
      GetUserData()
    }
  }, [user, token]);

  const child =
    (!userData) ? <Loader /> : (
      dataState === "error" ? (
        <Typography>An error occured.</Typography>
      ) : <Dashboard />
    )

  return (
    <ErrorBoundary>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        {child}
      </div>
    </ErrorBoundary>
  );
}

export default App;
