import { createSlice } from "@reduxjs/toolkit";
import * as apiService from "../services/api-service";
import { toast } from "react-toastify";

const initialState = {
  eventData: null,
  eventFullData: null,
  history: [],
  planData: null,
  eventRatings: [],
  shareLinks: [],
  isDeleteTreeconfirmModalOpen: false,
  userDataLoading: false,
  userData: null,
  selectedTreeId: "",
  selectedDeleteTreeId: "",
  selectedDeleteTreeName: "",
  selectedUpdateTreeId: "",
  loading: false,
  plansLoading: false,
  planGenerating: false,
  eventGenerating: false,
  visualizationType: 1,
  isTreeHistoryLoading: false,
  visibleRightPanel: "TreeHistory",
  totalPages: 1,
  selectedTreeNode: null,
  deleteConfirmationModalType: "",
  selectedTreePlannedNodeNames: [],
};

const isMobile = window.innerWidth < window.innerHeight

// Slice
const treeMapSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: (state) => {
      return initialState;
    },
    setEventData: (state, action) => {
      state.eventData = action.payload;
    },
    setEventFullData: (state, action) => {
      state.eventFullData = action.payload;
    },
    setHistory: (state, action) => {
      state.history = action.payload;
    },
    setHistoryLoading: (state, action) => {
      state.isTreeHistoryLoading = action.payload;
    },
    setPlanData: (state, action) => {
      state.planData = action.payload;
    },
    setEventRatings: (state, action) => {
      state.eventRatings = action.payload
    },
    setSelectedUpdateTreeId: (state, action) => {
      state.selectedUpdateTreeId = action.payload;
    },
    setIsConfirmDeleteModalOpen: (state, action) => {
      state.isDeleteTreeconfirmModalOpen = !state.isDeleteTreeconfirmModalOpen;
      state.deleteConfirmationModalType = action.payload?.type;
      state.selectedDeleteTreeId = action.payload?.id;
      state.selectedDeleteTreeName = action.payload?.name;
    },
    setSelectedTreeId: (state, action) => {
      state.selectedTreeId = action.payload;
    },
    setVisibleRightPanel: (state, action) => {
      state.visibleRightPanel = action.payload;
    },
    setPlanLoading: (state, action) => {
      state.plansLoading = action.payload;
    },
    setPlanGenerating: (state, action) => {
      state.planGenerating = action.payload;
    },
    setEventGenerating: (state, action) => {
      state.eventGenerating = action.payload;
    },
    setVisualizationType: (state, action) => {
      state.visualizationType = action.payload;
    },
    setUserDataLoading: (state, action) => {
      state.userDataLoading = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setSelectedTreePlannedNodeNames: (state, action) => {
      state.selectedTreePlannedNodeNames = action.payload;
    },
    setSelectedTreeNode: (state, action) => {
      state.selectedTreeNode = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload
    },
    setShareLinks: (state, action) => {
      state.shareLinks = action.payload
    }
  },
});

// Actions
export const {
  reset,
  setEventData,
  setEventFullData,
  setHistory,
  setIsConfirmDeleteModalOpen,
  setSelectedTreeId,
  setSelectedUpdateTreeId,
  setHistoryLoading,
  setTreeHistoryLoading,
  setVisibleRightPanel,
  setPlanLoading,
  setPlanGenerating,
  setEventGenerating,
  setVisualizationType,
  setPlanData,
  setEventRatings,
  setUserDataLoading,
  setUserData,
  setSelectedTreePlannedNodeNames,
  setSelectedTreeNode,
  setLoading,
  setTotalPages,
  setShareLinks,
} = treeMapSlice.actions;
export default treeMapSlice.reducer;

const parsedResponse = (res, type) => {
  const resultWithoutId = type === "edit" ? res : res.substring(res.indexOf("{", 1))
  const validateJSON = (text) => {
    try {
      return JSON.parse(text);
    } catch (error) {
      return;
    }
  }
  let stack = [];
  const brackets = {
    '"': '"',
    '[': ']',
    '{': '}',
  }
  for (const char of resultWithoutId) {
    let lastBracket = stack.slice(-1);
    if (brackets[char]) {
      if (char == '"' && lastBracket == '"') {
        stack.pop();
      }
      else stack.push(brackets[char])
    }
    else {
      if (char == lastBracket) {
        stack.pop();
      }
    }
  }
  return validateJSON(`${resultWithoutId} ${stack.reverse().join('')}`)
};

export const createEvent = (event, job_title, company_name, context, requestType, modelType, first_event, main_branches, sub_branches, specific_actions) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(setEventData(" "));
    dispatch(setEventFullData(" "));
    dispatch(setLoading(true));
    dispatch(setVisualizationType(1));
    dispatch(setEventGenerating(true));
    let responseData = "";
    apiService.createEvent({ event, job_title, company_name, context, requestType, modelType, first_event, main_branches, sub_branches, specific_actions })
      .then(async (response) => {
        if (response?.error) {
          notify(response.error.code, "error");
          // reject();
        }
        else if (response?.status === 200) {
          let historyData = getState().treeMap.history;
          dispatch(setVisibleRightPanel("TreeHistory"));
          let eventAdded = false;
          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          const loopRunner = true;

          while (loopRunner) {
            const { value, done } = await reader.read();
            if (done) {
              dispatch(setEventGenerating(false));
              break;
            }
            const decodedChunk = decoder.decode(value, { stream: true });
            responseData += decodedChunk;
            if (!eventAdded) {
              let newEvent;
              const eventId = JSON.parse(responseData.substring(0, responseData.indexOf("}") + 1))?.eventId;
              if (eventId) {
                setTimeout(() => {
                  dispatch(setLoading(false));
                }, 1500);
              }
              if (requestType === 1) {
                newEvent = { _id: eventId, employee_name: event, job_title, title: job_title, company_name, context, requestType, modelType, event_name: null, branches: { main_branches, sub_branches, specific_actions } }
              }
              else {
                newEvent = { _id: eventId, employee_name: event, job_title: null, title: event, company_name: null, context, requestType, modelType, event_name: event, branches: { main_branches, sub_branches, specific_actions } }
              }
              let updatedHistory = [newEvent, ...historyData]
              dispatch(setHistory(updatedHistory));
              dispatch(setSelectedTreeId(eventId));
              dispatch(setEventFullData(newEvent));
              eventAdded = true;
              let url = new URL(window.location.href);
              url.searchParams.set("eventId", updatedHistory[0]._id)
              window.history.pushState(null, '', url.toString());
            }
            parsedResponse(responseData) && dispatch(setEventData(parsedResponse(responseData)))
          }
        }
      })
      .catch((error) => {
        dispatch(setEventData(null));
        dispatch(setLoading(false));
        if (error?.response?.data?.code === 'ECONNRESET') {
          notify("Network error: Please check your internet connection.", "error");
        } else {
          console.log("ERROR MESSAGE", error);
          notify(error.message, "error");
        }
        reject(error);
      })
      .finally(() => {
        dispatch(setLoading(false));
        dispatch(setEventGenerating(false));
      });
  });
};

export const editEvent = (event, job_title, company_name, context, requestType, modelType, key, main_branches, sub_branches, specific_actions) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(setEventData(" "));
    dispatch(setLoading(true));
    const historyData = getState().treeMap.history;
    let responseData = "";
    dispatch(setSelectedTreeId(key));
    dispatch(setSelectedTreePlannedNodeNames([]));
    dispatch(setEventGenerating(true));
    apiService.editEvent({ key, event, job_title, company_name, context, requestType, modelType, main_branches, sub_branches, specific_actions })
      .then(async (response) => {
        if (response?.error) {
          notify(response.error.code, "error");
          // reject();
        }
        else if (response?.status === 200) {
          dispatch(setVisibleRightPanel("TreeHistory"));
          let eventEdited = false;
          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          const loopRunner = true;

          while (loopRunner) {
            const { value, done } = await reader.read();
            if (done) {
              dispatch(setEventGenerating(false));
              break;
            }
            const decodedChunk = decoder.decode(value, { stream: true });
            responseData += decodedChunk;
            if (!eventEdited) {
              setTimeout(() => {
                dispatch(setLoading(false));
              }, 1500);
              const updatedHistory = historyData.map((el) => el._id === key ?
                { ...el, employee_name: requestType === 1 && event, job_title, event_name: requestType === 2 && event, modelType, company_name, context, branches: { main_branches, sub_branches, specific_actions } } : el)
              dispatch(setHistory(updatedHistory));
              dispatch(setEventFullData({
                employee_name: requestType === 1 && event,
                event_name: requestType === 2 && event,
                job_title,
                title: requestType === 1 ? job_title : event,
                company_name,
                context,
                requestType,
                modelType,
                branches: { main_branches, sub_branches, specific_actions }
              }))
              eventEdited = true;
            }
            parsedResponse(responseData, "edit") && dispatch(setEventData(parsedResponse(responseData, "edit")))
          }
        }
      })
      .catch((error) => {
        dispatch(setLoading(false));
        dispatch(setEventData(null));
        if (error?.response?.data?.code === 'ECONNRESET') {
          notify("Network error: Please check your internet connection.", "error");
        } else {
          console.log("ERROR MESSAGE", error);
          notify(error.message, "error");
        }
        reject(error);
      })
      .finally(() => {
        dispatch(setLoading(false));
        dispatch(setEventGenerating(false));
      });
  });
};

export const getEvent = (key) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const historyData = getState().treeMap.history;
    if (historyData && getState().treeMap.selectedTreeId !== key) {
      dispatch(setLoading(true));
      dispatch(setSelectedTreeId(key));
      apiService.getEvent(key)
        .then((response) => {
          if (response?.error) {
            notify(response.error.code, "error");
            dispatch(setEventData(null));
            dispatch(setEventFullData(null));
            // reject();
          } else if (response.status === 200) {
            dispatch(getEventInfo(key))
            dispatch(setSelectedTreePlannedNodeNames(response.data?.event?.plans?.map((el) => el.node_name)));
            dispatch(setEventData(response.data?.event?.node));
            dispatch(setEventFullData(response.data?.event));
            dispatch(setShareLinks(response?.data?.event?.self_assessment_labels))
          }
          resolve();
        })
        .catch((error) => {
          dispatch(setEventData(null));
          dispatch(setEventFullData(null));
          dispatch(setLoading(false));
          // console.log("ERROR MESSAGE", error);
          notify(error.message, "error");
        })
    }
  });
};

export const addNewBranch = ({ key, context }) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const eventData = getState().treeMap.eventData;
    dispatch(setEventGenerating(true));
    let responseData = "";
    apiService.addNewBranch({ key, context })
      .then(async (response) => {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        if (response?.status !== 200) {
          const { value } = await reader.read();
          const decodedChunk = decoder.decode(value, { stream: true });
          JSON.parse(decodedChunk)?.message &&
            notify(JSON.parse(decodedChunk).message, "error");
          // reject();
        }
        else if (response?.status === 200) {
          const loopRunner = true;

          while (loopRunner) {
            const { value, done } = await reader.read();
            if (done) {
              dispatch(setEventGenerating(false));
              break;
            }
            const decodedChunk = decoder.decode(value, { stream: true });
            responseData += decodedChunk;
            parsedResponse(responseData, "edit") && dispatch(setEventData(eventData?.Pitfalls ?
              { Pitfalls: [parsedResponse(responseData, "edit"), ...eventData.Pitfalls] } :
              { name: eventData.name, children: [parsedResponse(responseData, "edit"), ...eventData.children] }))
          }
        }
      }
      )
      .catch((error) => {
        dispatch(setEventGenerating(false));
        notify(error.message, "error");
      })
  });
};

export const getEventInfo = (eventId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    apiService.getEventInfo(eventId)
      .then((response) => {
        if (response?.error) {
          notify("Something went wrong!", "error");
          dispatch(setEventRatings([]));
          // reject();
        } else if (response.status === 200) {
          dispatch(setEventRatings(response.data?.result));
          dispatch(setLoading(false));
        }
        resolve();
      })
      .catch((error) => {
        dispatch(setEventRatings(null));
        dispatch(setLoading(false));
        notify(error.message, "error");
      })
  });
};

export const rateAndNote = ({ node_name, note, rate }) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const eventId = getState().treeMap.selectedTreeId;
    const selectedEventRatings = getState().treeMap.eventRatings;
    apiService.rateAndNote({ node_name, eventId, note, rate })
      .then((response) => {
        if (response?.error) {
          notify("Something went wrong!", "error");
          // reject();
        } else if (response.status === 201) {
          dispatch(setEventRatings([...selectedEventRatings, response.data?.result]));
        }
        resolve();
      })
      .catch((error) => {
        // console.log("ERROR MESSAGE", error);
        notify(error?.message, "error");
      })
  });
};

export const editNodeInfo = ({ nodeId, note, rate }) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const selectedEventRatings = getState().treeMap.eventRatings,
      eventId = getState().treeMap.selectedTreeId
    apiService.editNodeInfo({ eventId, nodeId, note, rate })
      .then((response) => {
        if (response?.error) {
          notify("Something went wrong!", "error");
          // reject();
        } else if (response.status === 200) {
          dispatch(setEventRatings(selectedEventRatings.map(el => el._id == nodeId ? { ...el, rate, note } : el)));
        }
        resolve();
      })
      .catch((error) => {
        // console.log("ERROR MESSAGE", error);
        notify(error?.message, "error");
      })
  });
};

export const deleteBranch = (node_name) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const selectedEventId = getState().treeMap.selectedTreeId,
      currentEventData = getState().treeMap.eventData;
    function removeNodeByName(node, name) {
      let nodeData = node.Pitfalls ? { name: "event name", children: node.Pitfalls } : node
      return {
        ...nodeData,
        children: nodeData.children?.reduce((acc, child) => {
          const updatedChild = removeNodeByName(child, name);
          if (updatedChild.name === name) {
            updatedChild.children?.forEach(innerChild => {
              innerChild.children?.forEach(el => document.getElementById(el.name).style.opacity = 0);
              document.getElementById(innerChild.name).style.opacity = 0;
            });
            document.getElementById(updatedChild.name).style.opacity = 0;
          } else acc.push(updatedChild);
          return acc;
        }, [])
      };
    }
    apiService.deleteBranch(selectedEventId, node_name)
      .then((response) => {
        if (response?.error) {
          notify("Something went wrong!", "error");
          // reject();
        } else if (response.status === 200) {
          const newEventData = removeNodeByName(currentEventData, node_name)
          setTimeout(() => {
            dispatch(setEventData(newEventData))
          }, 200);
        }
        resolve();
      })
      .catch((error) => {
        // console.log("ERROR MESSAGE", error);
        notify(error?.message, "error");
      })
  });
};

export const getTreeHistory = (currentPage) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setHistoryLoading(true));
    apiService.getTreeHistory({
      currentPage
    })
      .then((response) => {
        if (response?.error) {
          notify(response.error.code, "error");
          dispatch(setHistory(null));
        } else if (response?.status === 200) {
          dispatch(setTotalPages(response.data?.paginate.totalPages))
          dispatch(setHistory(response.data?.events));
          dispatch(setHistoryLoading(false));
        }
        resolve(response);
      }).catch(error => {
        console.log("ERROR MESSAGE", error);
        notify(error.message, "error");
      })
  })
};

export const treeHistoryRemove = () => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const state = getState();
    let deleteEventId = state.treeMap.selectedDeleteTreeId,
      selectedEventId = state.treeMap.selectedTreeId;
    apiService.deleteEvent({ deleteEventId })
      .then((response) => {
        if (response?.error) {
          notify(response.error.code, "error");
        } else if (response.status === 200) {
          let updatedHistory = state.treeMap.history.filter((el) => el._id !== deleteEventId),
            url = new URL(window.location.href);
          if (updatedHistory?.length > 0) {

            if (url.searchParams.get("eventId") && (selectedEventId == deleteEventId)) {
              url.searchParams.set("eventId", updatedHistory[0]._id)
              window.history.pushState(null, '', url.toString());
              dispatch(getEvent(updatedHistory[0]._id))
            }
          }
          else if (updatedHistory?.length == 0) {
            url.searchParams.delete("eventId")
            window.history.pushState(null, '', url.toString());
            dispatch(setSelectedTreeId(""));
            dispatch(setEventFullData(null));
            dispatch(setEventData(null))
          }
          dispatch(setHistory(updatedHistory));
          dispatch(
            setIsConfirmDeleteModalOpen({ type: "event", id: "", name: "" })
          );
          notify("Event Removed Successfully!", "success")
        }
        resolve();
      })
      .catch((error) => {
        notify(error.message, "error");
      })
  })
};

export const eventTitleUpdate = (treeHistoryId, title, employee_name) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const state = getState();
    apiService
      .udpateEventTitle({
        treeHistoryId,
        title,
        employee_name
      })
      .then((response) => {
        if (response?.error) {
          notify(response.error.code, "error");
        }
        resolve();
      })
      .catch((error) => {
        notify(error.message, "error");
      });
  });
};

export const generatePlan = (nodeData) => (dispatch, getState) => {
  const SelectedTreePlannedNodeNames = getState().treeMap.selectedTreePlannedNodeNames;
  const selectedTreeId = getState().treeMap.selectedTreeId;

  if (SelectedTreePlannedNodeNames.includes(nodeData.data.name)) {
    return dispatch(getPlan(nodeData))
  } else return new Promise((resolve, reject) => {
    let responseData = "";
    dispatch(setSelectedTreeNode(nodeData));
    dispatch(setVisibleRightPanel("Plans"));
    dispatch(setPlanLoading(true));

    apiService.generatePlan({
      key: selectedTreeId,
      node_name: nodeData.data.name,
    }).then(async (response) => {
      if (response?.status === 200) {
        dispatch(setSelectedTreePlannedNodeNames([...SelectedTreePlannedNodeNames, nodeData.data.name]))
        dispatch(setPlanLoading(false));
        dispatch(setPlanGenerating(true))
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        const loopRunner = true;

        while (loopRunner) {
          const { value, done } = await reader.read();
          if (done) {
            dispatch(setPlanGenerating(false))
            break;
          }
          const decodedChunk = decoder.decode(value, { stream: true });
          responseData += decodedChunk;
          dispatch(setPlanData(responseData))
        }
      }
    })
      .catch((error) => {
        dispatch(setPlanData(null));
        if (error?.response?.data?.code === "ECONNRESET") {
          notify(
            "Unable to process your request. Please check your internet connection or try again later.",
            "error"
          );
        } else {
          notify(error.message, "error");
          console.log("ERROR MESSAGE", error);
        }
        dispatch(setPlanLoading(false));
        // reject();
      });
  });
};

export const getPlan = (nodeData) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const selectedTreeId = getState().treeMap.selectedTreeId;
    dispatch(setSelectedTreeNode(nodeData));
    dispatch(setVisibleRightPanel("Plans"));
    dispatch(setPlanLoading(true));

    apiService.getPlan({
      key: selectedTreeId,
      nodeName: nodeData.data.name
    })
      .then((response) => {
        if (response?.error) {
          notify(response.error.code, "error");
        } else if (response?.status === 200) {
          dispatch(setPlanData(response.data?.result));
          dispatch(setPlanLoading(false));
        }
        resolve(response);
      }).catch(error => {
        console.log("ERROR MESSAGE", error);
        notify(error.message, "error");
      })
  })
};

export const updatePlan = (key, node_name, context) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    let responseData = "";
    dispatch(setPlanLoading(true));
    apiService.updatePlan({
      key,
      node_name,
      context
    })
      .then(async (response) => {
        if (response?.error) {
          notify(response.error.code, "error");
        } else if (response?.status === 200) {
          dispatch(setPlanLoading(false));
          dispatch(setPlanGenerating(true))
          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          const loopRunner = true;

          while (loopRunner) {
            const { value, done } = await reader.read();
            if (done) {
              dispatch(setPlanGenerating(false))
              break;
            }
            const decodedChunk = decoder.decode(value, { stream: true });
            responseData += decodedChunk;
            dispatch(setPlanData(responseData))
          }
        }
        resolve();
      })
      .catch((error) => {
        dispatch(setPlanData(null));
        if (error?.response?.data?.code === "ECONNRESET") {
          notify(
            "Unable to process your request. Please check your internet connection or try again later.",
            "error"
          );
        } else {
          notify(error.message, "error");
          console.log("ERROR MESSAGE", error);
        }
        dispatch(setPlanLoading(false));
        // reject();
      });
  });
};

export const removePlan = (key, node_name) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const state = getState();
    apiService.removePlanRecord({
      key,
      node_name,
    }).then((response) => {
      if (response?.error) {
        notify(response.error.code, "error");
      } else if (response.status === 200) {
        notify("Plan Removed Successfully!", "success");
        dispatch(setPlanData(null));
        dispatch(setVisibleRightPanel("TreeHistory"));
        dispatch(
          setSelectedTreePlannedNodeNames(
            state.treeMap.selectedTreePlannedNodeNames.filter(
              (elm) => elm !== node_name
            )
          )
        );
        dispatch(setIsConfirmDeleteModalOpen({ type: "plan" }));
        document.getElementById(`spanNum${state.treeMap.selectedTreeNode.id}`)?.remove();
        dispatch(setSelectedTreeNode(null));
      }
      resolve();
    })
      .catch((error) => {
        notify(error.message, "error");
      })
  })
};

export const addShareLink = (label) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const selectedEventId = getState().treeMap.selectedTreeId;
    const shareLinks = getState().treeMap.shareLinks;

    apiService.createAssessmentUrl(selectedEventId, label)
      .then((response) => {
        if (response?.error) {
          notify("Something went wrong!", "error");
          // reject();
        } else if (response.status === 200) {
          dispatch(setShareLinks([...shareLinks, label]))
          navigator.clipboard.writeText(
            `${window.location.origin}/share?eventID=${selectedEventId}&label=${label}`
          );
          notify("Event link copied to clipboard", "success");
        }
        resolve();
      })
      .catch((error) => {
        // console.log("ERROR MESSAGE", error);
        notify(error?.message, "error");
      })
  });
};

export const deleteShareLink = (label) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const selectedEventId = getState().treeMap.selectedTreeId;
    const shareLinks = getState().treeMap.shareLinks;

    apiService.deleteAssessmentUrl(selectedEventId, label)
      .then((response) => {
        if (response?.error) {
          notify("Something went wrong!", "error");
          // reject();
        } else if (response.status === 200) {
          dispatch(setShareLinks(shareLinks.filter(el => el != label)))
        }
        resolve();
      })
      .catch((error) => {
        // console.log("ERROR MESSAGE", error);
        notify(error?.message, "error");
      })
  });
};

export const notify = (title, type) => {
  let color = "#3a9c3b";
  if (type === "error") {
    color = "#d73f35";
  } else if (type === "info") {
    color = "#3498DB"
  }
  return toast(title, {
    position: "top-right",
    autoClose: type === "success" ? 1000 : 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    type,
    style: {
      background: color,
      fontFamily: "ClashDisplay-Medium",
      width: isMobile && "80vw",
      margin: isMobile ? "4vw" : 0,
      borderRadius: "4px",
    },
    theme: "colored",
  });
};
