import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ReactComponent as BrandLogo } from "../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import PriceCard from "../components/PriceCard/PriceCard";
import { getPlansList } from "../services/api-service";
import Loader from "../components/Loader/Loader";
import CheckoutForm from "../components/CheckoutForm/CheckoutForm";
import { useAuth } from "../services/auth";
import { getUserData } from "../services/api-service";

const PricingPlans = () => {
  const { user, token } = useAuth();
  const [plansList, setPlansList] = useState([]);
  const [clientSecret, setClientSecret] = useState(null);
  const [userLoading, setUserLoading] = useState(false);
  const [hadFreeTrial, setHadFreeTrial] = useState(false);
  const navigate = useNavigate();

  const GetUserData = async () => {
    if (token) {
      setUserLoading(true);
      await getUserData(token)
        .then(async (res) => {
          setHadFreeTrial(res?.user.judo_Application.hadFreeTrial);
          getPlansList().then((res) => setPlansList(res?.data?.prices));
        })
        .catch(() => navigate("/signin"))
        .finally(() => setUserLoading(false));
    } else navigate("/signin");
  };
  useEffect(() => {
    if (!userLoading) {
      GetUserData();
    }
  }, [user]);

  return (
    <div>
      {plansList?.length ? (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            mt: "5vh",
          }}
        >
          <BrandLogo
            onClick={() => navigate("/signin")}
            style={{ alignSelf: "center", cursor: "pointer" }}
          />
          <Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, 300px)",
                justifyContent: "center",
                width: "100%",
                gap: "15px",
                m: "10% 0",
              }}
            >
              {plansList.map((plan, i) => (
                <PriceCard
                  hadFreeTrial={hadFreeTrial}
                  plan={plan}
                  key={i}
                  setClientSecret={setClientSecret}
                />
              ))}
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, 300px)",
                justifyContent: "center",
                width: "100%",
                gap: "15px",
                m: "25vh 0 5vh",
              }}
            >
              {plansList.map((_, i) =>
                i === 0 ? (
                  <div
                    style={{
                      width: "fit-content",
                      padding: "7px 14px",
                      border: "1px solid #2f2f2f",
                      borderRadius: 15,
                      color: "#2f2f2f",
                      cursor: "pointer",
                      fontFamily: "ClashDisplay-regular",
                    }}
                    onClick={() => navigate("/signin")}
                  >
                    Back to sign in
                  </div>
                ) : (
                  <div></div>
                )
              )}
            </Box>
            {clientSecret && (
              <CheckoutForm
                clientSecret={clientSecret}
                setClientSecret={setClientSecret}
              />
            )}
          </Box>
        </Box>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default PricingPlans;
