import React, { useRef, useState, useEffect } from "react";
import { TableRow } from "./TableRow";
import { Tooltip, IconButton, Button } from "@mui/material";
import { ReactComponent as CollapseIcon } from "../../assets/images/icons/collapse.svg";
import { ReactComponent as ExpandIcon } from "../../assets/images/icons/expand.svg";
import ShareLinksModal from "./ShareLinksModal";
import { IsMobile } from "../../utils/deviceType";
import { addNewBranch } from "../../reducers/treeMapSlice";
import TextEntryModal from "../TextEntryModal/TextEntryModal";

const IndentedList = ({
  isAssesmentLink,
  data,
  eventId,
  shareLinks,
  userData,
  selectedTreeNode,
  selectedTreePlannedNodeNames,
  eventRatings,
  originalEventRatings,
  // eventGenerating,
  dispatch,
}) => {
  const [collapseAll, setCollapseAll] = useState("finished");
  const [lastCollapseState, setLastCollapseState] = useState("fully_expanded");
  // const [EventGenerating, setEventGenerating] = useState(eventGenerating);
  const [EventRatings, setEventRatings] = useState([]);
  const [shareLinksOpened, setShareLinksOpened] = useState(false);
  const [addBranch, setAddBranch] = useState(false);
  const indetedListBottom = useRef(null);
  const isMobile = IsMobile();

  // useEffect(() => {
  //   if (EventGenerating) {
  //     indetedListBottom?.current?.scrollIntoView({
  //       behavior: "smooth",
  //       block: "end",
  //     });
  //   }
  // }, [data]);

  // useEffect(() => {
  //   setEventGenerating(eventGenerating);
  // }, [eventGenerating]);

  useEffect(() => {
    setEventRatings(eventRatings);
  }, [eventRatings]);

  const handleEventRatings = (operation, newRating) => {
    switch (operation) {
      case "create":
        setEventRatings([...EventRatings, newRating]);
        break;
      case "edit":
        setEventRatings(
          EventRatings.map((el) => (el._id === newRating._id ? newRating : el))
        );
        break;
      case "delete":
        setEventRatings(EventRatings.filter((el) => el._id !== newRating._id));
        break;
    }
  };

  const handleAddBranch = (context) => {
    dispatch(addNewBranch({ key: eventId, context }));
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="indentedListHeader">
        <div style={{ display: "flex" }}>
          {!isAssesmentLink && (
            <Button
              onClick={() => setAddBranch(true)}
              variant="outline"
              sx={{
                backgroundColor: "#eeeeee",
                fontFamily: "ClashDisplay-Regular",
                color: "#585858",
                textTransform: "capitalize",
                borderRadius: "5px",
                p: "1.5px 8px",
                ml: "5px",
              }}
            >
              Add branch
            </Button>
          )}
          {shareLinks?.length > 0 && (
            <Button
              onClick={() => setShareLinksOpened(true)}
              variant="outline"
              sx={{
                backgroundColor: "#eeeeee",
                fontFamily: "ClashDisplay-Regular",
                color: "#585858",
                textTransform: "capitalize",
                borderRadius: "5px",
                p: "1.5px 8px",
                ml: "5px",
              }}
            >
              View Share Links
            </Button>
          )}
        </div>
        <div
          style={{
            backgroundColor: "rgba(239, 239, 239, 1)",
            padding: 4,
            borderRadius: 4,
          }}
        >
          <Tooltip title="Expand All" arrow>
            <IconButton
              onClick={() => {
                setCollapseAll("fully_expanded");
                setLastCollapseState("fully_expanded");
              }}
              sx={{
                padding: "0px",
                margin: "0px 5px",
                backgroundColor: "transparent",
              }}
            >
              <ExpandIcon
                width={isMobile ? 18 : 14}
                height={isMobile ? 18 : 14}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Collapse" arrow>
            <IconButton
              onClick={() => {
                if (lastCollapseState === "fully_expanded") {
                  setCollapseAll("expanded");
                  setLastCollapseState("expanded");
                } else {
                  setCollapseAll("collapsed");
                  setLastCollapseState("collapsed");
                }
              }}
              sx={{
                padding: "0px",
                margin: "0px 5px",
                backgroundColor: "transparent",
              }}
            >
              <CollapseIcon
                width={isMobile ? 18 : 14}
                height={isMobile ? 18 : 14}
              />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div
        ref={indetedListBottom}
        id="currentList"
        style={{ width: "100%", paddingBottom: 20 }}
      >
        {data?.map((row, index) => (
          <TableRow
            isAssesmentLink={isAssesmentLink}
            rowData={row}
            firstChild
            key={row.name + "-" + index}
            collapseAll={collapseAll}
            setCollapseAll={setCollapseAll}
            userData={userData}
            selectedTreeNode={selectedTreeNode}
            selectedTreePlannedNodeNames={selectedTreePlannedNodeNames}
            eventRatings={EventRatings}
            originalEventRatings={originalEventRatings}
            handleEventRatings={handleEventRatings}
            dispatch={dispatch}
          />
        ))}
      </div>
      {shareLinks?.length > 0 && shareLinksOpened && (
        <ShareLinksModal
          eventId={eventId}
          shareLinks={shareLinks}
          open={shareLinksOpened}
          setOpen={setShareLinksOpened}
          dispatch={dispatch}
        />
      )}
      {addBranch && (
        <TextEntryModal
          header="Add new branch"
          inputPlaceholder="Enter context"
          buttonText="Add Branch"
          textEntryModalOpened={addBranch}
          setTextEntryModalOpened={setAddBranch}
          action={handleAddBranch}
        />
      )}
    </div>
  );
};

export default IndentedList;
