import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { Dialog } from "@mui/material";

export default function CheckoutForm({ clientSecret, setClientSecret }) {
  const stripePromise = loadStripe("pk_live_JimQPWhowa75rEsOPb4ANw3p");
  const options = { clientSecret };

  return (
    <div id="checkout" className="my-4">
      <Dialog
        open={clientSecret}
        onClose={() => setClientSecret(null)}
        sx={{
          textAlign: "center",
          backdropFilter: "blur(4px)",
          "& .MuiPaper-root": {
            borderRadius: "20px",
            padding: "0px 30px",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <div style={{ padding: "40px 10px" }}>
          <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </div>
      </Dialog>
    </div>
  );
}
